import { getMeasurementsFromDB } from 'api/api-manager'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Col, Row } from 'reactstrap'

const css = `@media (max-width: 1770px) {
    .steps-cal{
        flex-wrap: wrap
    }
   .steps-cal p{
        flex-basis: 50%
   }
}
@media screen and (max-width: 1450px) {
   .steps-cal p{
        flex-basis: 100%
   }
@media screen and (max-width: 1200px) {
    .steps-cal p{
         flex-basis: 80%
    }

@media screen and (max-width: 600px) {
    .steps-cal p{
         flex-basis: 60%
    }
@media screen and (max-width: 900px) {
        .steps-cal p{
             flex-basis: 70%
        }
}`

function CaloriesAndSteps({ patientId, steps, calories, configurations }) {
    const { measurementsFromDB } = useSelector(state => state)
    const sleep = {title: 'Sleep', unit: 'Sleeping hrs', value: measurementsFromDB[patientId]?.sleep || '---'}
    useEffect(() => {
        if(!measurementsFromDB[patientId]?.sleep){
            getMeasurementsFromDB(patientId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const isConfigOn = (key) => {
        return configurations?.measurements?.[key] === 'on'
    }

    return (
        <Card className="card-stats mb-4 mb-xl-2 align-middle">
            <CardBody style={{ padding: '5px 3px' }}>
                <Row className='m-0 justify-content-between'>
                    {[calories,steps, sleep].map(p => {
                        const pKey = p.title.toLowerCase()
                        return isConfigOn(pKey) &&
                        <Col xs="6" key={p.title}>
                            <style scoped>{css}</style>
                            <Row className='steps-cal align-items-end m-0 pb-2'>
                                <img style={{flexBasis:'45px'}} alt={p.title} src={require(`../../assets/img/icons/${pKey}${p.disabled ? ' disabled' : ''}.svg`)} width={45} />
                                <p style={{fontSize: '30px', lineHeight: 'initial'}} className='m-0 text-center px-2'>{p.value || '---'}</p>
                                <p style={{fontSize: '18px'}} className='m-0 text-center'>{p.unit || p.title}</p>
                            </Row>
                        </Col>
                    })}
                </Row>
            </CardBody>
        </Card >
    )
}

export default CaloriesAndSteps