import React,{useState,useEffect, useRef,forwardRef} from 'react'
import {Row, Col,Card, CardBody, CardHeader} from "reactstrap";
import axios from 'axios';
import DatePicker from 'react-datepicker'
import { connect, useDispatch, useSelector } from 'react-redux'
import moment from 'moment';

import { setSelectedDate, setMonthlyData } from 'store/actions'
import { getFirstDayInMonth } from 'utils';
import { emptyMonthlyData } from 'variables/charts';
import { setDailyData } from 'store/actions';
import { REPORTS_TITLES as reportTitles } from 'consts';
import PrintIcon from '../../assets/img/icons/print.svg'
import HeartLoader from 'components/icons/heart-loader/heart-loader';

export function ChartBox({title,date, setDate,monthlyData,setMonthlyData,children,token,handlePrint,patientId, role}) {
    const {dailyData} = useSelector(state => state)
    const [data, setData] = useState([])
    const [monthlyDate, setMonthlyDate] = useState(new Date(getFirstDayInMonth()))
    const [onLoad, setOnLoad] = useState()
    const [dateStr, setDateStr] = useState()
    const isDaily = title==='Daily Chart'
    const isToday = isDaily ? date.toLocaleDateString() === new Date().toLocaleDateString() : monthlyDate.getMonth() === new Date().getMonth()
    const dispatch = useDispatch()
    const isDoctor = role === 'doctor'
    const [month, setMonth] = useState()
    const minDate = moment(new Date()).subtract(6, 'months')

    useEffect(() => {
      if(!isDaily){
        setOnLoad(monthlyData?.[patientId]?.[moment(monthlyDate).format('YYYY-MM-DD')].onLoad)
      }
    }, [monthlyData])

    useEffect(() => {
      const keyDate = moment(date).format('YYYY-MM-DD');
      const todayData = dailyData?.[patientId]?.[keyDate]
      setDateStr(moment(date).format('YYYY/MM/DD'))//  date.toLocaleDateString())
      if(todayData){
        return  setData(todayData)
      }
      async function fetchData(){
        setOnLoad(true)
        try{
          const correctDate = moment(date).format('YYYY-MM-DD')
          const endPointApi = role === 'doctor' ? 'doctor/get-patient-daily' : 'measurements/get-daily'
          const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/app/${endPointApi}`,
            { day: correctDate, patientId:+patientId },
            { headers: {'Authorization': `Bearer ${token}` } }
          ).then(res=>res.data)
        setOnLoad(false)
        setData(res)
        dispatch( setDailyData(patientId, keyDate, res) )//to redux
        } catch(err){
          console.log('err',err)
          setOnLoad(false)
        }
      }
      isDaily&&fetchData()}, [date])

    const changeDate = (days)=>{
      if(onLoad) return
      if(isToday && days>0) return
      const newDate = new Date(date.getTime() + days*24*60*60*1000)
      const isMinDay = moment(newDate).date() < moment(new Date()).date()
      if(isMinMonth(newDate) && isMinDay) return
      setDate(newDate)
      setMonth(newDate)
    }

    const isMinMonth = (newDate)=>{
      return moment(newDate).month()+1 === (minDate.month()+1)
    }

    const changeMonth = (sign)=>{
      if(onLoad) return
      const newDate = new Date(monthlyDate)
      newDate.setDate(1)
      newDate.setMonth(monthlyDate.getMonth()+sign)
      if(moment(newDate).month() < minDate.month() && moment(newDate).year() <= minDate.year()) return
      // const newDateString = newDate.toLocaleDateString()
      const newDateString =  moment(newDate).format('YYYY-MM-DD');
      if(!monthlyData[patientId]?.[newDateString]){
          setMonthlyData(emptyMonthlyData(newDate),newDateString,true,patientId)
          const endPointApi = isDoctor ? 'doctor/get-patient-monthly' : 'measurements/get-monthly'
           axios.post(
          `${process.env.REACT_APP_API_URL}/app/${endPointApi}`,
            { day:newDateString, patientId:+patientId },
            { headers: {'Authorization': `Bearer ${token}` } }  
          ).then(res=> {
            const data = res.data || emptyMonthlyData(newDate)
            setMonthlyData(data, newDateString, false, patientId)
          }).catch(err=>{
            console.log('error fetching monthly data',err)
            setMonthlyData({},newDateString,false,patientId)
          })
      }
      setMonthlyDate(newDate)
      setMonth(newDate)
    }

    const DateInput = forwardRef(({ value, onClick }, ref) => (
      <>
        <i className="fas fa-arrow-left" 
            onClick={()=>isDaily ? changeDate(-1) : changeMonth(-1)} 
            style={{marginRight:'10px',cursor:onLoad?'':'pointer', opacity:onLoad?0.3:1}}/>

        <div style={{display:'inline', cursor:'pointer'}} onClick={onClick} ref={ref}>
          {isDaily ? new Date(value).toDateString().replace(' ',', ')
          :monthlyDate.toLocaleString('default', { month: 'long' })}
        </div>

        <i className="fas fa-arrow-right" 
            onClick={()=>isDaily ? changeDate(1) : changeMonth(1)} 
            style={{display:'inline',marginLeft:'10px',cursor:isToday||onLoad?'':'pointer', opacity:isToday||onLoad?0.3:1}}/>
      </>
    ));

    return (
      <div className="chart-box"  style={{marginTop:'20px',position:'relative'}} > 
       {onLoad && <HeartLoader size={300} />}

        <Card >
          <CardHeader className="bg-transparent" style={{padding:'0.85rem 1.5rem'}} >
            <Row style={{backgroundColor:isDaily?'inherit':'#EFEFEF',padding:'0.4rem 0rem', color: '#2f659c'}} >
              <Col xs="auto">
                {title}
              </Col>
              <Col>
                <DatePicker disabled={!isDaily}
                      selected={isDaily? date : monthlyDate} 
                      onChange={(date) => isDaily?setDate(date):setMonthlyDate(date)} 
                      // filterDate={date=>date<=new Date()}
                      showMonthYearPicker={!isDaily}
                      showFullMonthYearPicker={!isDaily}  
                      minDate={minDate.toDate()}
                      maxDate={new Date()}
                      onMonthChange={(v)=>{
                        setMonth(v)
                      }}                    
                      customInput={<DateInput/>}/>  
              </Col>
              <Col className="d-print-none"  xs="auto">
                <img alt="print" src={PrintIcon}  height="24px" style={{cursor:'pointer'}} onClick={handlePrint} />
              </Col>
            </Row>
            {isMinMonth(month) && <Row>
                <Col style={{ color: 'black', fontSize: 'smaller', fontWeight:'bold'}}>
                  {reportTitles['daily-report']?.['select-date-attention']}
                </Col>
              </Row>}
          </CardHeader>
            <CardBody className='overflow-auto'>
              <div className={`${isDaily && 'd-print-none'}`}>{React.cloneElement(children,{data,dayStr:isDaily?dateStr:moment(monthlyDate).format('YYYY-MM-DD')})}</div>
              {/* same chart just smaller for print mode */}
              {isDaily && <div className='print-daily-show' style={{width: '1200px'}}>{React.cloneElement(children,{data,dayStr:isDaily?dateStr:moment(monthlyDate).format('YYYY-MM-DD')})}</div>}
            </CardBody>
          </Card>
        </div>
    )
}

const mapStateToProps = ({selectedDate,monthlyData}) => ({date:selectedDate,monthlyData})
const mapDispatchToProps = {setDate:setSelectedDate, setMonthlyData}
export default connect(mapStateToProps, mapDispatchToProps)(ChartBox)
