import {useState} from 'react'
import { Navbar, NavbarToggler, Nav, NavItem, NavLink, Collapse} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux'
import SaveChangesGuard from "components/Modals/SaveChangesGuard";
import { setUnsavedChanges } from 'store/actions'

export  function SectionsNavbar({upperPath,title,subSections,children,unsavedChanges,setUnsavedChanges}) {
    const [saveGaurdModal, toggleSaveGaurdModal] = useState(false)
    const [saveGaurdLink, setSaveGaurdLink] = useState()
    const navigate = useNavigate()

    const onLinkClick = (link)=>{
        if(unsavedChanges){
            setSaveGaurdLink(link)
            toggleSaveGaurdModal(true)
        }
        else{
            navigate(link)
        }
    }

    const onGaurdLeave = ()=>{
        toggleSaveGaurdModal(false)
        setUnsavedChanges()
        navigate(saveGaurdLink)
    }

    const onGaurdConfirm = ()=>{
        unsavedChanges?.callback()
        navigate(saveGaurdLink)
        toggleSaveGaurdModal(false)
      }
    

    return (
        <>
        <SaveChangesGuard 
            isOpen={saveGaurdModal} 
            toggleModal={toggleSaveGaurdModal} 
            continueLink={saveGaurdLink}
            onLeave={onGaurdLeave}
            onConfirm={onGaurdConfirm} />
        <Navbar color="transperent" light expand="md" style={{paddingBottom:0,marginLeft:'8px'}} /*   className="border" */ >
            <NavbarToggler />
            <Collapse isOpen navbar>
                <Nav className="mr-auto w-100" navbar>
                    {subSections?.map(page=> page.title===title?
                    <NavLink key={page.title} style={{cursor:'pointer',color:'#3873AE',textDecoration:'underline',textUnderlineOffset:'9px'}} >{page.title}</NavLink>
                    :
                    <div className="nav-link" 
                        key={page.title}
                        onClick={()=>onLinkClick(`/${upperPath}/${page.link}`)} 
                        to={`/${upperPath}/${page.link}`} 
                        tag={Link}>
                        {page.title}
                    </div>
                    )}
                    <NavItem className="ml-auto" >{children}</NavItem>
                </Nav>
            </Collapse>
        </Navbar>
        </>
    )
}


const mapStateToProps = ({unsavedChanges}) => ({
    unsavedChanges
})

const mapDispatchToProps = {
    setUnsavedChanges
}

export default connect(mapStateToProps, mapDispatchToProps)(SectionsNavbar)
